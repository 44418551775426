<template>
  <div class="main-report">
    <div class="section-header">
      <h2>
        <span><i class="far fa-list-alt mr-2"></i></span>{{ $t("follow_schedule.follow") }}
      </h2>
    </div>
    <v-sheet class="card-select-date" width="100%">
      <v-row dense>
        <v-col cols="4" md="4" lg="3">
          <label class="label-input">{{
            $t("employee_ot.search_title")
          }}</label>
          <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense v-model="searchItem"
            prepend-inner-icon="mdi-magnify" :placeholder="$t('Search.search')" @keypress.enter="searchFilterItem">
          </v-text-field>
        </v-col>
        <v-col cols="4" md="4" lg="2">
          <label class="label-input">{{ $t("employee_ot.date") }}</label>
          <DatePicker class="date-time" style="width: 100%" type="date" v-model="date" valueType="format"
            slot="activator" :append-to-body="true" name="founding_date" @change="searchFilterItem()"></DatePicker>
          <!-- @change="searchFilterItem()" -->
        </v-col>


      </v-row>
    </v-sheet>
    <v-sheet class="mt-4 pa-4" width="100%" height="auto" color="white">
      <table class="table table-view" v-if="listEmployeeCheckin.length > 0">
        <thead>
          <tr>
            <th>#</th>
            <th class="text-left">
              {{ $t("follow_schedule.table.emp_number") }}
            </th>
            <th>
              {{ $t("follow_schedule.table.emp_name") }}
            </th>
            <th>
              {{ $t("follow_schedule.table.schedule_time") }}
            </th>
            <th>
              {{ $t("follow_schedule.table.status") }}
            </th>
            <th>
              {{ $t("follow_schedule.table.phone") }}
            </th>
            <th>
              {{ $t("follow_schedule.table.iduser") }}
            </th>
            <th>
              {{ $t("follow_schedule.table.point_scan") }}
            </th>
            <th>WIFI</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in listEmployeeCheckin" :key="idx">
            <td>{{ idx + 1 }}</td>
            <td>{{ item.employee.emp_number }}</td>
            <td>{{ item.employee.name }}</td>
            <td>
              {{ item.scan_time }} &nbsp;({{ item.schedule_detail.name }})
            </td>
            <td>
              <div v-if="item.check_in_status == 'success'">
                <v-icon color="success">mdi-check-circle</v-icon>
                {{ $t("follow_schedule.status.success") }}
              </div>
              <div v-else>
                <v-icon color="red">mdi-alert-circle</v-icon>
                <span v-if="item.check_in_status == 'pendding'">{{ $t("follow_schedule.status.fail") }}</span>
                <span v-if="item.check_in_status == 'not_yet'">{{ $t("follow_schedule.status.not_yet") }}</span>
                <span v-if="item.check_in_status == 'skip'">{{ $t("follow_schedule.status.skip") }}</span>
                <span v-if="item.check_in_status == 'not_in_area'">{{ $t("follow_schedule.status.not_in_area") }}</span>
              </div>

            </td>
            <td>{{ item.phone_model }}</td>
            <td>{{ item.phone_id }}</td>
            <td>
              <v-btn x-small class="ma-2" outlined fab color="teal">
                <a v-bind:href="'https://www.google.com/maps/dir//' +
                  item.latitude +
                  ',' +
                  item.longitude +
                  '/@' +
                  item.latitude +
                  ',' +
                  item.longitude +
                  ',400m/data=!3m1!1e3!4m2!4m1!3e0'
                  " target="_blank">
                  <v-icon>mdi-map-marker-outline</v-icon>
                </a>
              </v-btn>
            </td>
            <td><span v-if="item.wifi_ip">
                <p>IP: {{ item.wifi_ip }}</p>
                <p>Name: {{ item.wifi_name }}</p>
              </span></td>
          </tr>
        </tbody>
      </table>

      <defaultTableNoResult v-else />

      <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
        @paginate="fetchEmployeeCheckin">
      </Pagination>
    </v-sheet>
    <loading v-if="isLoading" />
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Loading from "@/components/Loading";
import Pagination from "@/components/Paginate/Pagination";
import "vue2-datepicker/index.css";
import defaultTableNoResult from "@/components/defaultTableNoResult";

export default {
  components: {
    Loading,
    Pagination,
    defaultTableNoResult,
  },
  data() {
    return {
      offset: 10,
      pagination: {},
      per_page: 10,
      dialog: false,
      searchItem: "",
      isLoading: false,
      listEmployeeCheckin: [],

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },

  methods: {
    searchFilterItem() {
      this.fetchEmployeeCheckin();
      this.pagination.current_page = 1;
    },

    fetchEmployeeCheckin() {
      this.isLoading = true;
      this.$axios
        .get(`company/tracking/employee/clockin`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
            date: this.date,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.listEmployeeCheckin = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listEmployeeCheckin.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchEmployeeCheckin();
  },
};
</script>

<style scoped lang="scss">
.margin-top-6 {
  margin-top: 6px;
}

.main-report {
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgba(223, 230, 233, 0.3);
  font-family: $font-family;

  h2 {
    font-size: 20px;
  }

  .card-select-date {
    display: flex;
    align-items: center;
    padding: 15px;
  }

  .section-header {
    padding: 13px;
    background-color: rgb(255, 255, 255) !important;
  }
}

.status {
  font-size: 12px;
  letter-spacing: 1px;
  padding: 2px 8px;
  width: 60px;
  height: 20px;
  text-transform: capitalize;
  font-family: $font-family;
  border-radius: 60px;
}

.pending {
  background-color: rgba(128, 142, 155, 1);
  color: #ffffff;
}

.confirm {
  background-color: rgba(46, 204, 113, 1) !important;
  color: #ffffff;
}

.updated {
  background-color: rgba(255, 221, 89, 1) !important;
  color: #ffffff;
}

.rejected {
  background-color: rgba(192, 57, 43, 1) !important;
  color: #ffffff;
}

.approve {
  background-color: rgba(38, 222, 129, 1);
  color: #ffffff;
}

.success {
  background-color: rgba(30, 144, 255, 1) !important;
  color: #ffffff;
}
</style>
