<template>
  <div class="main-container">
    <div class="content">
      <div class="section-topic">
        <div class="title-header">
          <h4 class="lfont">
            <i class="fa fa-sticky-note" aria-hidden="true"></i>
            {{ $t("e_leave.addLeave.title") }}
          </h4>
        </div>
      </div>
      <div class="section-form-content">
        <v-row>
          <v-col cols="12" md="2" lg="2"></v-col>
          <v-col cols="12" md="3" lg="3">
            <label class="label-input">{{
              $t("e_leave.addLeave.employee")
            }}</label>

            <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="employeeList" hide-selected
              v-model="employee_id" @change="fetchEmployeeHoliday" :hint="`${server_errors.employee_id}`">
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name + ' ' + data.item.surname"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
            <label class="label-input">{{ $t("e_leave.addLeave.year") }}</label>
            <v-select :items="years" outlined dense v-model="year" @change="fetchEmployeeHoliday"></v-select>
          </v-col>
          <v-col cols="12" md="4" lg="4">
            <label class="label-input">{{
              $t("e_leave.addLeave.holiday")
            }}</label>
            <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="typeHoliday" hide-selected
              v-model="employee_holiday_id" @change="getEmployeeHoliday" :hint="`${server_errors.employee_holiday_id}`">
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
            <div v-if="remainingHoliday">
              <h3 class="remaining-holiday">
                {{ $t("e_leave.addLeave.remainingDay.title") }}(
                <label>
                  {{ remainingHoliday.days }}
                  {{ $t("e_leave.addLeave.remainingDay.days") }}
                </label>
                <label>
                  {{ remainingHoliday.hours }}
                  {{ $t("e_leave.addLeave.remainingDay.hours") }}
                </label>
                <label>
                  {{ remainingHoliday.minute }}
                  {{ $t("e_leave.addLeave.remainingDay.minute") }} </label>)
              </h3>
              <br /><br />
            </div>
            <v-row>
              <v-col cols="12" md="6" lg="6">
                <label class="label-input">{{
                  $t("e_leave.addLeave.startDate")
                }}</label>
                <DatePicker class="date-time" style="width: 100%" type="date" valueType="format" slot="activator"
                  :append-to-body="true" v-model="listEmployee_leave.start_date" @change="genLeaveDate"
                  :hint="`${server_errors.startDate}`">
                </DatePicker>
              </v-col>
              <v-col cols="12" md="6" lg="6">
                <label class="label-input">{{
                  $t("e_leave.addLeave.endDate")
                }}</label>
                <DatePicker class="date-time" style="width: 100%" type="date" valueType="format" slot="activator"
                  :append-to-body="true" v-model="listEmployee_leave.end_date" @change="genLeaveDate"
                  :hint="`${server_errors.endDate}`">
                </DatePicker>
              </v-col>
            </v-row>

            <v-list shaped>
              <v-list-item-group>
                <template v-for="(item, i) in datesLeave">
                  <v-list-item :key="`item-${i}`">
                    <template dense>
                      <v-list-item-icon style="padding: 6px">
                        <v-icon>mdi-clock</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content @click="addLeaveTime(item)">
                        <v-list-item-title v-text="item.date +
                          ' (' +
                          item.start_time +
                          ' - ' +
                          item.end_time +
                          ')'
                          " v-if="item.start_time != null && item.end_time != null
    "></v-list-item-title>

                        <v-list-item-title v-text="item.date" v-else></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-btn icon color="pink" @click="removeDate(item.date)">
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
            <!-- <h4 class="text-center lfont" v-if="datesLeave.length > 0">
                  3 {{ $t("e_leave.addLeave.day") }} 5
                  {{ $t("e_leave.addLeave.hour") }}
                </h4> -->
            <v-checkbox class="lfont" v-model="attFile" :label="$t('e_leave.addLeave.attFile')"></v-checkbox>
            <v-file-input class="lfont" show-size v-model="listEmployee_leave.file_name"
              :label="$t('e_leave.addLeave.chooseFile')" v-if="attFile"
              :hint="`${server_errors.file_name}`"></v-file-input>
            <v-checkbox class="lfont" v-model="comeBackDate" :label="$t('e_leave.addLeave.comeBackDate')"></v-checkbox>
            <DatePicker class="date-time" style="width: 100%" type="date" valueType="format" slot="activator"
              :append-to-body="true" v-model="listEmployee_leave.come_back_date" v-if="comeBackDate">
            </DatePicker>

            <label class="label-input">{{
              $t("e_leave.addLeave.detail")
            }}</label>
            <v-textarea solo name="input-7-4" v-model="listEmployee_leave.remark"
              :hint="`${server_errors.remark}`"></v-textarea>
            <label class="label-input">{{
              $t("e_leave.addLeave.lineManager")
            }}</label>
            <v-autocomplete filled dense item-text="name" item-value="user_id" outlined :items="lineManagerList"
              hide-selected v-model="approve_by_id">
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name + ' ' + data.item.surname"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
            <v-btn class="ma-2 btn-save lfont" color="secondary" @click="saveEmployeeLeave">{{ $t("e_leave.addLeave.edit")
            }}</v-btn>
          </v-col>
          <v-col cols="12" md="2" lg="2"></v-col>
        </v-row>
      </div>
    </div>
    <v-dialog v-model="dialogEditLeaveTime" scrollable width="300">
      <v-card>
        <v-card-title class="lfont">{{
          $t("e_leave.addLeave.add_time")
        }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="lfont">
            <h1>{{ $t("e_leave.addLeave.start_time") }}</h1>
            <v-text-field type="time" v-model="start_time"></v-text-field>
            <h1>{{ $t("e_leave.addLeave.end_time") }}</h1>
            <v-text-field type="time" v-model="end_time"></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn variant="text" class="btn-save" @click="updateDateLeave">
            {{ $t("action.save") }}
          </v-btn>
          <v-btn color="red" variant="text" @click="dialogEditLeaveTime = false" class="btn-cancel">
            {{ $t("action.cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import moment from "moment"; // import xlsx;
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from "@/components/Loading";
export default {
  components: {
    DatePicker,
    Loading
  },
  data() {
    return {
      isLoading: false,
      dialogEditLeaveTime: false,
      listEmployee_leave: [],
      leave_id: "",
      attFile: false,
      comeBackDate: true,
      dateComabck: "",
      datesLeave: [],
      employeeList: [],
      lineManagerList: [],
      lineManagerId: "",
      typeHoliday: [],
      employee_holiday_id: "",
      approve_by_id: "",
      employee_id: "",
      start_date: false,
      end_date: false,
      years: [],
      year: new Date().getFullYear(),
      remainingHoliday: false,
      remark: "",
      start_time: "",
      end_time: "",
      datesLeaveEdit: [],
      server_errors: {
        employee_id: "",
        employee_holiday_id: "",
        leave_time: "",
        come_back_date: "",
        remark: "",
        file_name: "",
        approved_by: "",
        startDate: "",
        endDate: "",
      },
    };
  },
  methods: {
    addLeaveTime(item) {
      this.dialogEditLeaveTime = true;
      this.datesLeaveEdit = item;
    },

    updateDateLeave() {
      let date = this.datesLeaveEdit.date;
      let start_time = this.start_time;
      let end_time = this.end_time;
      this.datesLeave = this.datesLeave.filter(function (items) {
        if (items.date == date) {
          items.start_time = start_time;
          items.end_time = end_time;
        }
        return items;
      });
      this.dialogEditLeaveTime = false;
      this.start_time = "";
      this.end_time = "";
    },

    removeDate(date) {
      this.datesLeave = this.datesLeave.filter(function (item) {
        return item.date != date;
      });
    },
    genLeaveDate() {
      if (
        this.listEmployee_leave.start_date &&
        this.listEmployee_leave.end_date
      ) {
        var date1 = new Date(this.listEmployee_leave.start_date);
        var date2 = new Date(this.listEmployee_leave.end_date);
        var Difference_In_Time = date2.getTime() - date1.getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        for (var i = 0; i <= Difference_In_Days; i++) {
          var d = new Date(this.listEmployee_leave.start_date);
          d.setDate(date1.getDate() + i);
          d = moment(d).format("YYYY-MM-DD");
          let check = this.datesLeave.filter(item => item.date === d);
          if (check.length == 0) {
            this.datesLeave.push({
              id: 0,
              date: d,
              start_time: null,
              end_time: null,
            });
          }
        }
      } else {
        this.datesLeave = [];
      }
    },

    saveEmployeeLeave() {
      this.isLoading = true;
      let item = {
        employee_id: this.employee_id,
        employee_holiday_id: this.employee_holiday_id,
        amount: null,
        holiday_type_id: null,
        startDate: this.listEmployee_leave.start_date,
        endDate: this.listEmployee_leave.end_date,
        leave_time: this.datesLeave,
        come_back_date: this.listEmployee_leave.come_back_date,
        remark: this.listEmployee_leave.remark,
        file_name: this.listEmployee_leave.file_name,
        approved_by: this.approve_by_id,
        // year: this.year,
        // from: "admin",
      };
      this.$axios
        .put(`company/edit/leave/${this.leave_id}`, item)
        .then((res) => {
          if (res.status === 200) {
            this.$router
              .push({
                name: "e-leave.index",
              })
              .catch(() => { });
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
    getEmployeeHoliday() {
      let holiday = {
        ...this.filterEmployeeHoliday(this.holiday_id),
      };
      this.remainingHoliday = holiday.remaining_holiday;
    },
    filterEmployeeHoliday(holiday_id) {
      return (
        this.typeHoliday.filter((item) => {
          return item.id == holiday_id;
        })[0] || {}
      );
    },
    getYears() {
      let year = this.year - 4;
      let years = [];
      for (let i = 5; i > 2; i--) {
        years.push(year + i);
      }
      this.years = years;
    },

    fetchEmployeeLeave() {
      this.$axios.get(`company/update/leave/${this.leave_id}`).then((res) => {
        if (res.status === 200) {
          this.listEmployee_leave = res.data.data;
          this.datesLeave = res.data.data.leave_time;
          this.employee_id = res.data.data.employee.id;
          this.approve_by_id = res.data.data.approved_by.id;
          this.employee_holiday_id = res.data.data.holiday.employee_holiday_id;
          this.fetchEmployeeHoliday();

          // console.log(res);
        }
        this.isLoading = false;
      });
    },

    fetchEmployee() {
      this.$axios.get(`company/all-employee-name`).then((res) => {
        if (res.status === 200) {
          this.employeeList = res.data.data;
        }
        this.isLoading = false;
      });
    },
    fetchLineManage() {
      this.$axios.get(`company/only/line/managers`).then((res) => {
        if (res.status === 200) {
          this.lineManagerList = res.data.data;
        }
        this.isLoading = false;
      });
    },
    fetchEmployeeHoliday() {
      this.holiday_id = "";
      this.remainingHoliday = false;
      this.$axios
        .get(`company/employee/list/holidays`, {
          params: {
            employee_id: this.employee_id,
            year: this.year,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.typeHoliday = res.data.data;
          }
          this.isLoading = false;
        });
    },
    // getEmployeeHoliday() {},
    // genLeaveDate() {},
  },
  created() {
    this.leave_id = this.$route.params.leave_id;

    // if (this.employee_id === null) {
    //   this.employee_id = this.$route.params.employee_id;
    // }
    this.fetchEmployeeLeave();
    this.getYears();
    this.fetchEmployee();
    this.fetchLineManage();
  },
};
</script>

<style lang="scss" scoped>
.section-form-content {
  * {
    font-size: 14px !important;
  }

  padding-top: 20px;

  .btn-save {
    background: rgb(8, 189, 255) !important;
    color: white;
  }

  .remaining-holiday {
    color: red;
    font-family: $font-family;
  }
}
</style>
