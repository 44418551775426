<template>
  <div class="main-report">
    <div class="section-header">
      <h2>
        <span><i class="far fa-list-alt mr-2"></i></span>{{ $t("eLeave.eLeave") }}
      </h2>
    </div>
    <v-sheet class="card-select-date" width="100%">
      <v-row dense>
        <v-col cols="2" md="2" lg="4">
          <label class="label-input">{{
            $t("employee_ot.search_title")
          }}</label>
          <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense v-model="filter"
            prepend-inner-icon="mdi-magnify" :placeholder="$t('Search.search')">
          </v-text-field>
          <!-- @keypress.enter="searchFilterItem" -->
        </v-col>
        <v-col cols="2" md="2" lg="2">
          <label class="label-input">{{ $t("eLeave.startDate") }}</label>
          <v-menu v-model="startDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="start_date" readonly outlined dense v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="start_date" @input="startDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2" md="2" lg="2">
          <label class="label-input">{{ $t("eLeave.endDate") }}</label>
          <v-menu v-model="endDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="end_date" readonly outlined dense v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="end_date" @input="endDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2" md="2" lg="2">
          <label class="label-input">{{ $t("eLeave.statusSearch") }}</label>
          <v-select outlined dense :items="todoStatus" item-value="value" item-text="name" v-model="status">
          </v-select>
        </v-col>
        <v-col cols="2" md="2" lg="2">
          <label class="label-input">{{ $t("eLeave.leaveType") }}</label>
          <v-select outlined dense :items="leaveType" item-value="id" item-text="name" v-model="leaveTypeSelected">
          </v-select>
        </v-col>
        
        <v-row cols="1" md="1" lg="4" style="padding: 0 20px 20px 20px;margin-top: -40px;">
          <v-btn class="btn-action" style="margin-top: 1.6rem; height: 40px;margin-left: 0;" @click="fetchELeave">
            {{ $t("eLeave.btnSearch") }}
          </v-btn>
          <v-btn class="btn-action confirm" style="margin-top: 1.6rem; height: 40px;margin-left: 0;"
            @click="exportExcel">
            <v-icon>mdi-microsoft-excel</v-icon> Export
          </v-btn>
          <v-btn class="btn-action" style="margin-top: 1.6rem; height: 40px;margin-left: 0;" @click="addNewLeave"
            color="primary">
            {{ $t("e_leave.addLeave.title") }}
          </v-btn>
        </v-row>
      </v-row>
    </v-sheet>
    <v-sheet class="mt-4 pa-4" width="100%" height="auto" color="white">
      <table class="table">
        <thead>
          <tr>
            <th >#</th>
            <th class="text-left">
              {{ $t("eLeave.eLeaveNumber") }}
            </th>
            <th>
              {{ $t("eLeave.emp_number") }}
            </th>
            <th>
              {{ $t("eLeave.employee") }}
            </th>
            <th>
              {{ $t("eLeave.department") }}
            </th>
            <th>
              {{ $t("eLeave.leaveType") }}
            </th>
            <th>
              {{ $t("eLeave.amountUse") }}
            </th>
            <th align="center">
              {{ $t("eLeave.file") }}
            </th>
            <th>
              {{ $t("eLeave.approvedBy") }}
            </th>
            <th>
              {{ $t("eLeave.lmstatus") }}
            </th>
            <th>
              {{ $t("eLeave.approvedByHr") }}
            </th>
            <th>
              {{ $t("eLeave.hrstatus") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in Eleaves" :key="idx">
            <td>
              <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined v-bind="attrs" v-on="on">
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title class="btn-edit" @click="onEdit(item.id)">Edit
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title class="btn-delete" @click="onDeleted(item.id, item.employee.id)">Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td class="text-start">{{ item.leave_number }}</td>
            <td>{{ item.employee.emp_number }}</td>
            <td>{{ item.employee.name }} {{ item.employee.surname }}</td>
            <td>{{ item.employee.company_department.name }}</td>
            <td>{{ item.holiday ? item.holiday.name : "" }}</td>
            <td class="text-primary">
              <div class="display-flex">
                <div class="margin-top-6">
                  <div v-if="item.amount > 0">
                    {{ item.amount }}
                    <span v-if="item.type == 'day'">{{
                      $t("eLeave.day")
                    }}</span>
                    <span v-if="item.type == 'hour'">{{
                      $t("eLeave.hour")
                    }}</span>
                  </div>
                  <div v-else>
                    <span style="margin-right: 10px" v-if="item.summary_leave.day > 0">{{ item.summary_leave.day }} {{
                      $t("eLeave.day") }}
                    </span>
                    <span v-if="item.summary_leave.hour > 0">{{ item.summary_leave.hour }}
                      {{ $t("eLeave.hour") }}</span>
                    <span v-if="item.summary_leave.minute > 0"> {{ item.summary_leave.minute }}
                      {{ $t("eLeave.minute") }}</span>
                  </div>
                </div>
                <v-btn icon color="indigo" @click="showLeaveDetail(item.id)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </div>
            </td>
            <td align="center">
              <v-btn v-if="hasValidExtension(item.file_name)" icon color="indigo" @click="openFile(item.file_name)">
                <v-icon center>
                  mdi-file-eye
                </v-icon>
              </v-btn>
              <v-icon v-else center>
                mdi-file-eye
              </v-icon>
            </td>
            <td v-if="item && item.approved_by">
              {{ item.approved_by.name }}
              <span class="text-primary" v-if="item.lm_confirm_at">
                ({{ moment(item.lm_confirm_at).format("DD/MM/YYYY HH:mm:ss") }})
              </span>
            </td>
            <td class="text-start">
              <div v-if="item.lm_status === 'pending'">
                <span class="status pending">pending</span>
              </div>
              <div v-if="item.lm_status === 'approved'">
                <span class="status approve">approve</span>
              </div>
              <div v-if="item.lm_status === 'rejected'">
                <span class="status rejected">rejected</span>
              </div>
            </td>
            <td v-if="item && item.approved_by_hr">
              {{ item.approved_by_hr.name ? item.approved_by_hr.name : "-" }}
              <span class="text-primary" v-if="item.hr_confirm_at">(
                {{ moment(item.hr_confirm_at).format("DD/MM/YYYY HH:mm:ss") }}
                )</span>
            </td>
            <td v-else></td>
            <td class="text-start">
              <div v-if="item.hr_status === 'pending'">
                <span class="status pending">pending</span>
              </div>
              <div v-if="item.hr_status === 'approved'">
                <span class="status approve">approve</span>
              </div>
              <div v-if="item.hr_status === 'rejected'">
                <span class="status rejected">rejected</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <ModalEdit>
        <template v-slot="{ close }">
          <ShowLeaveDetail :LeaveDetail="LeaveDetail" @close="close" />
        </template>
      </ModalEdit>

      <ModalDelete fullscreen title="Edit Employee Leave">
        <template v-slot="{ close }">
          <Delete :leave_id="leave_id" :employee_id="employee_id" @close="close" @success="fetchELeave()" />
        </template>
      </ModalDelete>

      <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset" @paginate="fetchELeave">
      </Pagination>
    </v-sheet>
    <loading v-if="isLoading" />
  </div>
</template>

<script>
import moment from "moment";
import Loading from "@/components/Loading";
import ShowLeaveDetail from "@/views/Report/Modals/ShowLeaveDetail";
import Pagination from "@/components/Paginate/Pagination";
import Delete from "./Delete";

export default {
  name: "TodoList",
  components: {
    Loading,
    ShowLeaveDetail,
    Pagination,
    Delete,
  },
  data() {
    return {
      offset: 10,
      pagination: {},
      per_page: 10,
      LeaveDetail: {},
      dialog: false,
      status: "all",
      isLoading: false,
      leave_id: "",
      employee_id: "",
      leaveType: [],
      leaveTypeSelected: "",
      todoStatus: [
        {
          id: 0,
          name: this.$t("statusEleave.all"),
          value: "all",
        },
        {
          id: 1,
          name: this.$t("statusEleave.pending"),
          value: "pending",
        },
        {
          id: 2,
          name: this.$t("statusEleave.approved"),
          value: "approved",
        },
        {
          id: 3,
          name: this.$t("statusEleave.success"),
          value: "success",
        },
        {
          id: 3,
          name: this.$t("statusEleave.rejected"),
          value: "rejected",
        },
      ],
      filter: "",
      startDate: false,
      start_date: "",
      endDate: false,
      end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      Eleaves: [],
      leave_id: "",
    };
  },

  methods: {
    openFile(url) {
      window.open(url, "_blank", "noreferrer");
    },
    hasValidExtension(url) {
      return /\.(?:[a-z0-9-]+)$/i.test(url);
    },
    onEdit(leave_id) {
      this.$store.commit("Employee/EMPLOYEE_LEAVE_ID", leave_id);
      this.$router.push({
        name: "e-leave.edit",
        params: {
          leave_id: leave_id,
        },
      });
    },

    onDeleted(leave_id, employee_id) {
      this.leave_id = leave_id;
      this.employee_id = employee_id;
      this.$store.commit("modalDelete_State", true);
    },

    filterDepartment(id) {
      return (
        this.Eleaves.filter((item) => {
          return item.id == id;
        })[0] || {}
      );
    },
    showLeaveDetail(id) {
      this.LeaveDetail = {
        ...this.filterDepartment(id),
      };
      this.$store.commit("modalEdit_State", true);
    },
    fetchELeave() {
      this.isLoading = true;
      const item = {
        start_date: moment(this.start_date).format("YYYY-MM-DD"),
        end_date: moment(this.end_date).format("YYYY-MM-DD"),
        status: this.status,
        filter: this.filter,
        holiday_type_id: this.leaveTypeSelected,
        per_page: this.per_page,
        page: this.pagination.current_page,
      };
      this.$axios
        .post(`company/report-leaves`, item)
        .then((res) => {
          this.isLoading = false;
          if (res.status === 200) {
            this.Eleaves = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.Eleaves.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    exportExcel() {
      const item = {
        start_date: moment(this.start_date).format("YYYY-MM-DD"),
        end_date: moment(this.end_date).format("YYYY-MM-DD"),
        status: this.status,
        filter: this.filter,
        holiday_type_id: this.leaveTypeSelected,
      };
      this.isLoading = true;

      this.$axios.post(`company/export/leaves-to-excel/v2`, item)
        .then((res) => {
          this.isLoading = false;
          this.$router
            .push({ name: "company.tracking-export-excel" })
            .catch(() => { });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
      // this.$axios
      //   .post(`company/export-leaves-to-excel`, item, {
      //     responseType: "blob",
      //   })
      //   .then((res) => {
      //     const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
      //     const fileLink = document.createElement("a");
      //     fileLink.href = fileUrl;
      //     fileLink.setAttribute("download", "e-leave.xlsx");
      //     document.body.appendChild(fileLink);
      //     fileLink.click();
      //     document.body.removeChild(fileLink);
      //     this.isLoading = false;
      //   })
      //   .catch((error) => {
      //     this.isLoading = false;
      //   });
    },
    addNewLeave() {
      this.$router
        .push({
          name: "e-leave.add.new",
        })
        .catch(() => { });
    },
    fetchCompanyHoliday() {
      this.$axios
        .get(`company/list/holidays`)
        .then((res) => {
          if (res) {
            this.leaveType = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    let date = new Date();
    let firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    this.start_date = moment(firstDate).format("YYYY-MM-DD");
    this.fetchELeave();
    this.fetchCompanyHoliday();
  },
};
</script>

<style scoped lang="scss">
.margin-top-6 {
  margin-top: 6px;
}

.main-report {
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgba(223, 230, 233, 0.3);
  font-family: $font-family;

  h2 {
    font-size: 20px;
  }

  .card-select-date {
    display: flex;
    align-items: center;
    padding: 15px;
  }

  .section-header {
    padding: 13px;
    background-color: rgb(255, 255, 255) !important;
  }
}

.status {
  font-size: 12px;
  letter-spacing: 1px;
  padding: 2px 8px;
  width: 60px;
  height: 20px;
  text-transform: capitalize;
  font-family: $font-family;
  border-radius: 60px;
}

.pending {
  background-color: rgba(128, 142, 155, 1);
  color: #ffffff;
}

.confirm {
  background-color: rgba(46, 204, 113, 1) !important;
  color: #ffffff;
}

.updated {
  background-color: rgba(255, 221, 89, 1) !important;
  color: #ffffff;
}

.rejected {
  background-color: rgba(192, 57, 43, 1) !important;
  color: #ffffff;
}

.approve {
  background-color: rgba(38, 222, 129, 1);
  color: #ffffff;
}

.success {
  background-color: rgba(30, 144, 255, 1) !important;
  color: #ffffff;
}
</style>
